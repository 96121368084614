import { apiEnvironment } from '../smoothr-web-app-core/environments/apiEnvironment';

export const threeMansKebabCustomerGroup = '3menskebab';
export const customerGroup = threeMansKebabCustomerGroup;

const supportEmail = {
	'3menskebab': '3menskebab@smoothr.de',
};
const firebaseConfig = {
	'3menskebab': {
		apiKey: 'AIzaSyDzopA0HXMwlMKLKBu93uzX5y_1EgseEec',
		authDomain: 'dev-env-216009.firebaseapp.com',
		databaseURL: 'https://dev-env-216009.firebaseio.com',
		projectId: 'dev-env-216009',
		storageBucket: 'dev-env-216009.appspot.com',
		messagingSenderId: '1004449889395',
		appId: '1:1004449889395:web:63e1c4915f112ce7e0fe06',
		measurementId: 'G-48VL48KH3K',
	},
};
export const environment = {
	customerGroup,
	webAppUrl: window.location.protocol + '//' + window.location.host,
	placesApiKey: 'AIzaSyCPU8cft5Houbh_UH0NTknDRLqD39BNOTo',
	SUPPORT_EMAIL: supportEmail[customerGroup],
	firebaseConfig: firebaseConfig[customerGroup],
	APAY_MERCHANT_ID: 'merchant.smoothr.' + customerGroup + '-dev',
	...apiEnvironment,
	countryList: ['de'],
	gaId: ''
};

import 'zone.js/dist/zone-error';
