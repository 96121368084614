import { environment } from 'src/environments/environment';
import { Component, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';

const stateKey = 'allow-gps-state';

export enum AllowGpsModalResult {
	ALLOW,
	DISALLOW,
}

@Component({
	selector: 'app-allow-gps-modal',
	templateUrl: './allow-gps-modal.component.html',
	styleUrls: ['allow-gps-modal.component.scss'],
})
export class AllowGpsModalComponent implements OnInit {
	environment = environment;
	constructor(private modalCtrl: ModalController) {}

	static show(modalCtrl: ModalController): Promise<AllowGpsModalResult> {
		return new Promise<AllowGpsModalResult>(async resolve => {
			const modal = await modalCtrl.create({
				component: AllowGpsModalComponent,
				cssClass: 'allow-gps-modal auto-height',
			});
			await modal.present();
			const result = await modal.onDidDismiss();
			if (result.data === undefined) {
				resolve(AllowGpsModalResult.DISALLOW);
			} else {
				resolve(result.data);
			}
		});
	}

	ngOnInit() {}

	onPositiveClick() {
		this.modalCtrl.dismiss(AllowGpsModalResult.ALLOW);
	}

	onNegativeClick() {
		this.modalCtrl.dismiss(AllowGpsModalResult.DISALLOW);
	}
}
