import { Component, Input } from '@angular/core';
import Article from '../../../smoothr-web-app-core/models/Article';
import { PreorderType } from '../../../smoothr-web-app-core/enums/PreorderType';
import { OrderType } from '../../../smoothr-web-app-core/enums/OrderType';
import ArticleGroup from '../../../smoothr-web-app-core/models/ArticleGroup';
import { OrderUtils } from '../../../smoothr-web-app-core/utils/order-utils';
import { environment } from 'src/environments/environment';
import { defaultsToArticleOption, getPrice, numberToCurrency } from '../../../smoothr-web-app-core/utils/utils';
import Address from '../../../smoothr-web-app-core/models/Address';

@Component({
	selector: 'app-item-view',
	templateUrl: './item-view.component.html',
	styleUrls: ['item-view.component.scss'],
})
export class ItemViewComponent {
	@Input() currency: string;
	price: number;
	ot = OrderType;
	environment = environment;
	@Input() hasOrder = false;
	priceText = '';

	constructor() {}

	private _article: Article;

	get article(): Article {
		return this._article;
	}

	@Input()
	set article(value: Article) {
		this._article = value;
		this.reloadPrice();
	}

	private _preorderType: PreorderType = null;

	get preorderType(): PreorderType {
		return this._preorderType;
	}

	@Input()
	set preorderType(value: PreorderType) {
		this._preorderType = value;
		this.reloadPrice();
	}

	private reloadPrice() {
		if (this.article && this.preorderType) {
			if (this.article.isFromPrice) {
				const articleGroup = new ArticleGroup();
				articleGroup.article = this.article;
				articleGroup.groups = defaultsToArticleOption(this.article, [], this.article.defaults, this.preorderType);
				articleGroup.quantity = 1;
				this.price = OrderUtils.totalPrice(articleGroup, OrderType.PREORDER, this.preorderType);
			} else {
				this.price = getPrice(this.article, OrderType.PREORDER, this.preorderType);
			}
		} else {
			this.price = 0;
		}
		this.priceText = numberToCurrency(this.price, this.currency);
	}
}
