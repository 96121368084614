import { apiEnvironment } from '../smoothr-web-app-core/environments/apiEnvironment';

export const threeMansKebabCustomerGroup = '3menskebab';
export const customerGroup = threeMansKebabCustomerGroup;
const supportEmail = {
	'3menskebab': '3menskebab@smoothr.de',
};
const firebaseConfig = {
	'3menskebab': {
		apiKey: 'AIzaSyB1Y4wG6BoFE2hi8irI30ofKfscXoEPPDw',
		authDomain: 'prod-env-smoothr.firebaseapp.com',
		databaseURL: 'https://prod-env-smoothr.firebaseio.com',
		projectId: 'prod-env-smoothr',
		storageBucket: 'prod-env-smoothr.appspot.com',
		messagingSenderId: '640058019537',
		appId: '1:640058019537:web:ac07c8396e48773acbfff0',
		measurementId: 'G-87X5E1314Y',
	},
};
export const environment = {
	customerGroup,
	webAppUrl: window.location.protocol + '//' + window.location.host,
	placesApiKey: 'AIzaSyD2no4P32BcJ1ls4t4z8XOlWijou-aZcBc',
	SUPPORT_EMAIL: supportEmail[customerGroup],
	firebaseConfig: firebaseConfig[customerGroup],
	APAY_MERCHANT_ID: 'merchant.smoothr.' + customerGroup + '-dev',
	...apiEnvironment,
	countryList: ['de'],
	gaId: ''
};
