import Order from '../models/Order';
import ArticleOption from '../models/ArticleOption';
import Payment from '../models/Payment';
import { PaymentStatus } from '../models/PaymentStatus';
import OptionGroup from '../models/OptionGroup';
import GroupDependency from '../models/GroupDependency';
import { OrderStatus } from '../enums/OrderStatus';
import Venue from '../models/Venue';
import * as moment from 'moment';
import { Moment } from 'moment';
import SlotsConfig from '../models/SlotsConfig';
import Slot from '../models/Slot';
import Hours from '../models/Hours';
import { PreorderType } from '../enums/PreorderType';
import { RepositoryService } from '../services/repository/repository.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { environment } from '../../environments/environment';
import { AxiosError } from 'axios';
import { TranslateService } from '@ngx-translate/core';
import { Api } from '../api/api';
import { TimeUtils } from './time-utils';
import { OrderUtils } from './order-utils';
import { OrderType } from '../enums/OrderType';
import { TerminalorderType } from '../enums/TerminalorderType';
import Offset from '../models/Offset';
import Article from '../models/Article';
import OptionDefault from '../models/OptionDefault';
import { ModalController } from '@ionic/angular';
import { PaymentSuccessPage } from '../../app/pages/payment-success/payment-success.page';
import { Router } from '@angular/router';
import { ByType } from '../models/ByType';
import { ValidationUtils } from './validation-utils';
import { isMongoId } from 'validator';
import { Observable } from 'rxjs';
import TagUtils from './tag-utils';
import { DisplayMode } from '../enums/DisplayMode';

// export const phoneCountries: PhoneCountry[] = [
// 	{
// 		code: 'de',
// 		tel: '+49'
// 	},
// 	{
// 		code: 'au',
// 		tel: '+43'
// 	}
// ].filter(cc => environment.countryList.indexOf(cc.code) >= 0);
export const currencySymbols = {
	eur: '€',
	chf: 'CHF',
};

export const sanitizeId = (id: string) => {
	if (!id.startsWith(environment.customerGroup + '_') && !isMongoId(id)) {
		return environment.customerGroup + '_' + id;
	}
	return id;
};
export const getRequiredArticles = (
	venue: Venue,
	preorderType: PreorderType,
): Article[] => {
	if (preorderType) {
		return [];
	}
	const requiredTag =
		preorderType === PreorderType.TAKE_AWAY
			? 'take_away_required'
			: 'delivery_required';
	const requiredArticles: Article[] = [];
	if (venue && venue.articleCategories.length > 0) {
		for (const category of venue.articleCategories) {
			for (const article of category.articles) {
				if (article.tags.find(tag => tag.identifier === requiredTag)) {
					requiredArticles.push(article);
				}
			}
		}
	}
	return requiredArticles;
};

export const isVenueOpen = (venue: Venue): boolean => {
	return (
		venue &&
		venue.isServiceActivated &&
		venue.openingHours.length > 0 &&
		TimeUtils.doesHoursMatchNow(venue.openingHours)
	);
};

export const sleep = (ms: number) => {
	return new Promise(resolve => setTimeout(resolve, ms));
};

export const venueAcceptsOrders = (
	venue: Venue,
	preorderType: PreorderType,
	overridePostalDelivery = false,
): boolean => {
	if (!venue) {
		return false;
	}
	if (!venue.isServiceActivated || !venue.isPublished) {
		return false;
	}
	if (venue.panicEndAt && moment(venue.panicEndAt).isAfter(moment())) {
		return false;
	}
	switch (preorderType) {
		case PreorderType.TAKE_AWAY:
			if (!venue.preorderTakeAway) {
				return false;
			}
			break;
		case PreorderType.INSIDE:
			if (!venue.preorderIn) {
				return false;
			}
			break;
		case PreorderType.DELIVERY:
			if (!venue.deliveryEnabled) {
				return false;
			}
			if (
				venue.deliveryByRadius &&
				venue.distance !== undefined &&
				venue.distance > venue.deliveryRadius
			) {
				return false;
			}
			if (
				venue.deliveryPostalCodes &&
				!venue.deliveryByRadius &&
				!(venue.isPostalDelivery || overridePostalDelivery)
			) {
				return false;
			}
			break;
	}
	return getRelevantSchedule(venue, preorderType).length !== 0;
};

export const isSlotFullByOrder = (
	slotConfig: SlotsConfig,
	slot: Slot,
	order: Order,
): boolean => {
	return isSlotFull(
		slotConfig,
		slot,
		order.type,
		order.preorder?.type,
		order.terminalorder?.type,
	);
};
export const isSlotFull = (
	slotConfig: SlotsConfig,
	slot: Slot,
	orderType: OrderType,
	preorderType: PreorderType,
	terminalOrderType: TerminalorderType = null,
): boolean => {
	if (!slot) {
		return false;
	}
	return !isValidSlot(
		slotConfig,
		slot,
		orderType,
		preorderType,
		terminalOrderType,
	);
};

const isValidSlot = (
	slotConfig: SlotsConfig,
	slot: Slot,
	orderType: OrderType,
	preorderType: PreorderType,
	terminalOrderType: TerminalorderType,
): boolean => {
	if (slot.total >= slotConfig.max) {
		return false;
	}
	const other = slot.total - slot.terminal.count;

	const defaultValidation: boolean =
		Math.max(slot.terminal.count, slotConfig.reserved) + other < slotConfig.max;

	switch (orderType) {
		case OrderType.TERMINAL:
			switch (terminalOrderType) {
				case TerminalorderType.INSIDE:
					return slot.terminal.type.inside < slotConfig.terminal.inside;
				case TerminalorderType.TAKE_AWAY:
					return slot.terminal.type.take_away < slotConfig.terminal.takeAway;

				default:
					return true;
			}
		case OrderType.PREORDER:
			if (!defaultValidation) {
				return false;
			}
			switch (preorderType) {
				case PreorderType.DELIVERY:
					return slot.preorder.type.delivery < slotConfig.preorder.delivery;
				case PreorderType.INSIDE:
					return slot.preorder.type.inside < slotConfig.preorder.inside;
				case PreorderType.TAKE_AWAY:
				case PreorderType.PARK_COLLECT:
					return (
						slot.preorder.type.take_away + slot.preorder.type.park_collect <
						slotConfig.preorder.takeAway
					);

				default:
					return true;
			}

		default:
			return defaultValidation;
	}
};

export const paymentFromOrder = (order: Order, tip: number = 0): Payment => {
	const payment = new Payment();
	payment.orders = [order._id];
	payment.status = PaymentStatus.created;
	payment.currency = order.currency;
	payment.sum = OrderUtils.orderTotalPrice(order, true, true);
	payment.tip = tip;
	return payment;
};

export const numberToCurrency = (price: any, currencyCode: string): string => {
	if (!currencyCode) {
		return '' + numberD(price);
	}
	return numberD(price).toLocaleString(navigator.language, {
		style: 'currency',
		currency: currencyCode,
	});
};

export const sendOrder = async (
	router: Router,
	modalCtrl: ModalController,
	translate: TranslateService,
	repository: RepositoryService,
	order: Order,
	tip,
	loading: (loading) => void,
	snackbarCtrl: MatSnackBar,
) => {
	order._id = undefined;
	loading(true);
	try {
		order.table = repository._venue.preorderTable;
		order.status = OrderStatus.CREATED;
		await repository.sendOrderAndPay(
			modalCtrl,
			order,
			tip,
			repository._authLogin ? repository._authLogin.token : null,
			repository._customerAuth.customer,
		);
		await PaymentSuccessPage.navigate(
			router,
			repository._verifiedOrder._id,
			repository._payment._id,
		);
	} catch (err) {
		if (err !== null) {
			const message = axiosErrorToMessage(translate, err, order.orderAt);
			console.error(message);
			snackbarCtrl.open(message, null, {
				duration: 5000,
			});
		}
		order.orderAt = null;
		repository.order.emit(order);
	}
	loading(false);
};

export const array = (count: number): number[] => {
	const arr: number[] = [];
	for (let i = 0; i < count; i++) {
		arr.push(i);
	}
	return arr;
};

export const nullOrEmpty = (str: string) => {
	return (
		str === undefined ||
		str === null ||
		str.length === undefined ||
		str.length === 0
	);
};

export const orderStatusString = (
	translate: TranslateService,
	orderStatus: OrderStatus,
): string => {
	switch (orderStatus) {
		case OrderStatus.CREATED:
			return translate.instant('order_status.created');
		case OrderStatus.AWAITING_CONFIRMATION:
			return translate.instant('order_status.awaiting_confirmation');
		case OrderStatus.DONE:
			return translate.instant('order_status.done');
		case OrderStatus.READY:
			return translate.instant('order_status.ready');
		case OrderStatus.IN_PREPARATION:
			return translate.instant('order_status.in_preparation');
		case OrderStatus.CANCELLED_BY_CUSTOMER:
			return translate.instant('order_status.canceled_by_customer');
		case OrderStatus.CANCELLED_BY_WAITER:
			return translate.instant('order_status.canceled_by_waiter');
		default:
			return translate.instant('order_status.default');
	}
};

export const removeFromArray = <T>(arr: T[], index): T[] => {
	const remainingItems = [...arr.slice(0, index), ...arr.slice(index + 1)];
	while (arr.length) {
		arr.pop();
	}
	arr.push(...remainingItems);
	return arr;
};

export const replaceOptions = (
	options: ArticleOption[],
	optionGroup: OptionGroup,
	articleOptions: ArticleOption[],
	times: number,
	dependsOn: string,
	dependency: GroupDependency,
) => {
	if (times > 0) {
		articleOptions.map(value => {
			value.dependencyNumber = times;
			value.dependsOn = dependsOn;
			value.dependency = dependency._id;
		});
	}
	options = options.filter(
		articleOption => articleOption.group !== optionGroup._id,
	);
	options.push(...articleOptions);
};

export const last = <T>(arr: T[]): T => {
	if (arr.length) {
		return arr[arr.length - 1];
	} else {
		return null;
	}
};

export const axiosErrorToMessage = (
	translate: TranslateService,
	error: AxiosError,
	time: Moment = null,
): string => {
	if (!error.isAxiosError) {
		return (error as Error).message;
	}
	if (!error.response || !error.response.data) {
		switch (error.code) {
			case 'ECONNABORTED':
				return translate.instant('axios_errors.econ', {
					support_email: environment.SUPPORT_EMAIL,
				});
		}
		switch (error.message) {
			case 'Network Error':
				return translate.instant('axios_errors.network');
		}
		return '' + error;
	}
	switch (error.response.status) {
		case 408:
			return translate.instant('axios_errors.408', {
				support_email: environment.SUPPORT_EMAIL,
			});
		case 500:
			return translate.instant('axios_errors.400_default', {
				name: error.response.data.name,
				status: error.response.status,
				code: error.response.data.code,
				msg: error.response.data.message,
			});
		case 422:
			switch (error.response.data.code) {
				case -1:
					if (error.response.data.errors && error.response.data.errors.promo) {
						return translate.instant('axios_errors.promo');
					}
					return translate.instant('axios_errors.400_default', {
						name: error.response.data.name,
						status: error.response.status,
						code: error.response.data.code,
						msg: error.response.data.message,
					});
				// panic
				case 2003:
					return translate.instant('axios_errors.2003', {
						time: moment(time).format('HH:mm'),
					});
				// service inactive
				case 2023:
					return translate.instant('axios_errors.2023', {
						support_email: environment.SUPPORT_EMAIL,
					});
				// order data is invalid
				case 2004:
					if (error.response.data.errors) {
						if (
							error.response.data.errors.orderAt ||
							error.response.data.errors.orderedAt
						) {
							return translate.instant('axios_errors.2004_order', {
								time: moment(time).format('HH:mm'),
							});
						} else if (error.response.data.errors.phone) {
							return translate.instant('axios_errors.2004_phone');
						} else if (error.response.data.errors.promo) {
							return translate.instant('axios_errors.2004_promo');
						}
					}
					return translate.instant('axios_errors.2004');
			}
			return translate.instant('axios_errors.422', {
				support_email: environment.SUPPORT_EMAIL,
			});
		case 400:
			switch (error.response.data.code) {
				// error during payment
				case 2512:
				case 2516:
					return translate.instant('axios_errors.2516');
				case 6103:
					return translate.instant('axios_errors.' + error.response.data.name);
			}
			return translate.instant('axios_errors.400', {
				support_email: environment.SUPPORT_EMAIL,
			});
		default:
			return translate.instant('axios_errors.400_default', {
				name: error.response.data.name,
				status: error.response.status,
				code: error.response.data.code,
				msg: error.response.data.message,
			});
	}
};

export const getSlotsByOrder = async (
	venue: Venue,
	order: Order,
	daysAdded = !!order.orderedArticles.find(it =>
		TagUtils.hasNextDayTag(it.article),
	)
		? 1
		: 0,
	extraSlots = false,
	requiredValidDays = 7,
): Promise<Slot[]> => {
	return await getSlots(
		venue,
		order.type,
		order.preorder?.type,
		order.terminalorder?.type,
		daysAdded,
		extraSlots,
		requiredValidDays,
	);
};

export const getSlots = async (
	venue: Venue,
	orderType: OrderType = OrderType.PREORDER,
	preorderType: PreorderType,
	terminalOrderType: TerminalorderType = null,
	daysAdded = 0,
	extraSlots = false,
	requiredValidDays = 7,
): Promise<Slot[]> => {
	if (
		!venue ||
		!venue.isServiceActivated ||
		!venue.slot ||
		!venue.slot.active
	) {
		return [];
	}
	const offset = getOffset(venue, orderType, preorderType, terminalOrderType);
	const now = moment()
		.add(offset.offset, 'minutes')
		.seconds(0)
		.milliseconds(0)
		.add(1, 'minute');
	const schedules = getRelevantSchedule(
		venue,
		preorderType,
		daysAdded,
		extraSlots,
		requiredValidDays,
	);
	const slots: Slot[] = [];
	let withAddedWeeks = 0;
	for (const [index, schedule] of schedules.entries()) {
		const hours = TimeUtils.hoursToSchedule(schedule);
		withAddedWeeks = Math.max(
			schedules.filter((prevHours, prevIndex) => {
				return prevHours === schedule && prevIndex < index;
			}).length,
			withAddedWeeks,
		);
		const from = hours.openedAt
			.add(schedule.connectedWithPrev ? 0 : offset.first, 'minutes')
			.add(withAddedWeeks * 7, 'days');
		const to = hours.closedAt
			.subtract(offset.last, 'minutes')
			.add(withAddedWeeks * 7, 'days');
		const minFrom = from.isBefore(now) ? now : from;
		if (minFrom.isSameOrAfter(to)) {
			continue;
		}
		try {
			const response = await Api.getSlots(
				venue._id,
				minFrom.toISOString(),
				to.toISOString(),
			);
			slots.push(
				...response.data.filter(
					slot =>
						!isSlotFull(
							venue.slot,
							slot,
							orderType,
							preorderType,
							terminalOrderType,
						),
				),
			);
		} catch (e) {
		}
	}
	return slots.map(slot => {
		slot.time = moment(slot.time);
		return slot;
	});
};

/**
 * Koos specific do not replace
 * @param venue venue
 * @param daysAdded days from today to look at. daysAdded = 1 => first slots to show is tomorrow,
 * 										 		daysAdded = 0 => first slots to show is today
 * @param preorderType venueType
 * @param extraSchedules extraSchedules
 * @param requiredValidDays requiredValidDays
 */
export const getRelevantSchedule = (
	venue: Venue,
	preorderType: PreorderType,
	daysAdded = 0,
	extraSchedules = false,
	requiredValidDays = 7,
): Hours[] => {
	const offset = getOffset(venue, OrderType.PREORDER, preorderType);
	const now = moment()
		.add(offset.offset, 'minutes')
		.seconds(0)
		.milliseconds(0)
		.add(1, 'minute');
	const relevantSchedule =
		preorderType === PreorderType.DELIVERY
			? venue.deliveryHours
			: venue.openingHours;
	const schedules = [];
	let validDays = 0;
	let plusDays = daysAdded;
	while (validDays < requiredValidDays && plusDays < 30) {
		const sch = TimeUtils.getSchedulesOfDay(
			now.clone().add(plusDays, 'day'),
			relevantSchedule,
		);
		if (sch.length !== 0) {
			validDays++;
			schedules.push(...sch);
		}
		plusDays++;
	}
	return schedules;
};

export const firstOrderTime = (
	venue: Venue,
	type: PreorderType,
): Moment | null => {
	if (!venue) {
		return null;
	}
	const offset = getOffset(venue, OrderType.PREORDER, type);
	const relevantSchedule = getRelevantSchedule(venue, type);
	const first = TimeUtils.hoursToSchedule(
		relevantSchedule.length !== 0 ? relevantSchedule[0] : null,
	);
	return first ? first.openedAt.add(offset.first, 'minutes') : null;
};

export const getLatLng = (
	venue: Venue,
): { latitude: number; longitude: number } | null => {
	if (!venue || !venue.location || !venue.location.coordinates) {
		return null;
	}
	const latitude = venue.location.coordinates[1];
	const longitude = venue.location.coordinates[0];
	return {
		latitude,
		longitude,
	};
};

export const getOffset = (
	venue: Venue,
	orderType: OrderType,
	preorderType: PreorderType = null,
	terminalOrderType: TerminalorderType = null,
): Offset => {
	if (!venue.offsets) {
		if (orderType === OrderType.PREORDER) {
			return {
				first: venue.firstPreorderOffset,
				offset: venue.preorderOffset,
				last: venue.lastPreorderOffset,
			};
		} else {
			return {
				first: 0,
				offset: 0,
				last: 0,
			};
		}
	}
	return resolveByType(
		venue.offsets,
		orderType,
		preorderType,
		terminalOrderType,
	);
};

export const getAvailability = (
	article: Article,
	orderType: OrderType,
	preorderType: PreorderType = null,
	terminalOrderType: TerminalorderType = null,
	hiddenAllowed = false,
): boolean => {
	if (!article.isActive) {
		return false;
	}
	if (!hiddenAllowed && article.hidden) {
		return false;
	}
	if (!article.availability) {
		return article.isActive;
	}
	return isAvailable(
		article.availability,
		orderType,
		preorderType,
		terminalOrderType,
	);
};

export const resolveByType = <T>(
	byType: ByType<T>,
	orderType: OrderType = null,
	preorderType: PreorderType = null,
	terminalOrderType: TerminalorderType = null,
	forceParkCollect: boolean = false,
): T => {
	switch (orderType) {
		case OrderType.PREORDER:
			switch (preorderType) {
				case PreorderType.DELIVERY:
					return byType.preorder.delivery;
				case PreorderType.INSIDE:
					return byType.preorder.inside;
				case PreorderType.PARK_COLLECT:
					if (forceParkCollect) {
						return byType.preorder.parkCollect;
					}
					return resolveByType(byType, orderType, PreorderType.TAKE_AWAY);
				case PreorderType.TAKE_AWAY:
					return byType.preorder.takeAway;
				default:
					return byType.standard;
			}
		case OrderType.TERMINAL:
			switch (terminalOrderType) {
				case TerminalorderType.INSIDE:
					return byType.terminal.inside;
				case TerminalorderType.TAKE_AWAY:
					return byType.terminal.takeAway;
				default:
					return byType.standard;
			}
		case OrderType.STANDARD:
			return byType.standard;
	}
	return byType.standard;
};

export const getBasePrice = (
	article: Article,
	orderType: OrderType,
	preorderType: PreorderType,
	terminalOrderType: TerminalorderType = null,
): number => {
	if (!article.basePriceByType) {
		return +article.basePrice.$numberDecimal;
	}
	return numberD(
		+resolveByType(
			article.basePriceByType,
			orderType,
			preorderType,
			terminalOrderType,
		),
	);
};

export const getPrice = (
	article: Article,
	orderType: OrderType,
	preorderType: PreorderType,
	terminalOrderType: TerminalorderType = null,
): number => {
	if (!article.priceByType) {
		return numberD(article.price);
	}
	try {
		const candidate = numberD(
			resolveByType(
				article.priceByType,
				orderType,
				preorderType,
				terminalOrderType,
			),
		);
		if (candidate && !isNaN(candidate)) {
			return +candidate;
		}
	} catch (e) {
	}
	return numberD(article.price);
};

export const getPriceWithDefaults = (
	article: Article,
	orderType: OrderType,
	preorderType: PreorderType,
	terminalOrderType: TerminalorderType = null,
): number => {
	const articlePrice = getPrice(
		article,
		orderType,
		preorderType,
		terminalOrderType,
	);
	return (
		articlePrice +
		defaultsToArticleOption(
			article,
			[],
			article.defaults,
			preorderType,
			terminalOrderType,
		)
			.map(it =>
				getPrice(it.article, orderType, preorderType, terminalOrderType),
			)
			.reduce((prev, curr) => prev + curr, 0)
	);
};

export const defaultsToArticleOption = (
	article: Article,
	selectedOptions: ArticleOption[],
	recommendations: OptionDefault[],
	preorderType: PreorderType,
	terminalOrderType: TerminalorderType = null,
	hiddenAllowed = false,
): ArticleOption[] => {
	const result: ArticleOption[] = [];
	result.push(...selectedOptions);
	for (const def of recommendations) {
		const group = article.groups.find(grp => grp._id === def.group);
		const dependency = ValidationUtils.isGroupDependencyFulfilled(
			article,
			result,
			group,
		);
		if (dependency.times < 0) {
			console.log(
				'dependency of group: ' +
				group.name.de +
				' not fulfilled for recommendation',
			);
			continue;
		}
		const defaultOption = new ArticleOption();
		defaultOption.group = def.group;
		defaultOption.quantity = def.quantity;
		defaultOption.article = group.articles.find(art => art._id === def.article);
		defaultOption.dependsOn = dependency.dependsOn ?? null;
		defaultOption.dependencyNumber = dependency.times;
		defaultOption.dependency = dependency.dependency?._id ?? null;
		if (
			(!defaultOption.article.hidden || hiddenAllowed) &&
			getAvailability(
				defaultOption.article,
				OrderType.PREORDER,
				preorderType,
				terminalOrderType,
				hiddenAllowed,
			)
		) {
			OrderUtils.addOption(result, defaultOption, group, dependency);
		}
	}
	return result;
};
export const defaultsToArticleOptionForOrder = (
	article: Article,
	defaults: OptionDefault[],
	order: Partial<Order>,
): ArticleOption[] => {
	return defaultsToArticleOption(
		article,
		[],
		defaults,
		order.preorder && order.preorder.type,
		order.terminalorder && order.terminalorder.type,
	);
};

export const getCounterArticle = (
	allArticles: Article[],
	article: Article,
): Article => {
	return article.counterArticle
		? allArticles.find(art => art._id === article.counterArticle)
		: undefined;
};

export const isActive = (
	allArticles: Article[],
	article: Article,
	orderType: OrderType,
	preorderType: PreorderType,
	terminalOrderType: TerminalorderType = null,
): boolean => {
	const counter = getCounterArticle(allArticles, article);
	return (
		!article.hidden &&
		getAvailability(article, orderType, preorderType) &&
		(!counter ||
			!isActive(
				allArticles,
				counter,
				orderType,
				preorderType,
				terminalOrderType,
			))
	);
};

export const isActiveForOrder = (
	allArticles: Article[],
	article: Article,
	order: Partial<Order>,
): boolean => {
	return isActive(
		allArticles,
		article,
		order.type,
		order.preorder && order.preorder.type,
		order.terminalorder && order.terminalorder.type,
	);
};

export const isAvailable = (
	availability: ByType<boolean>,
	orderType: OrderType = null,
	preorderType: PreorderType = null,
	terminalOrderType: TerminalorderType = null,
): boolean => {
	try {
		return resolveByType(
			availability,
			orderType,
			preorderType,
			terminalOrderType,
		);
	} catch (e) {
		return true;
	}
};

export const loadFacebookPixel = (id: string) => {
	const nodeId = 'facebook-pixel';
	if (id) {
		const script =
			document.getElementById(nodeId) || document.createElement('script');
		script.id = nodeId;
		script.innerHTML =
			'!function(f,b,e,v,n,t,s)\n' +
			'{if(f.fbq)return;n=f.fbq=function(){n.callMethod?\n' +
			'n.callMethod.apply(n,arguments):n.queue.push(arguments)};\n' +
			'if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version=\'2.0\';\n' +
			'n.queue=[];t=b.createElement(e);t.async=!0;\n' +
			't.src=v;s=b.getElementsByTagName(e)[0];\n' +
			's.parentNode.insertBefore(t,s)}(window, document,\'script\',\n' +
			'\'https://connect.facebook.net/en_US/fbevents.js\');\n' +
			'\'fbq(\'init\', \'id\');\n' +
			'fbq(\'track\', \'PageView\');';
		if (!script.parentNode) {
			document.body.appendChild(script);
		}
	} else {
		const script = document.getElementById(nodeId);
		if (script) {
			document.removeChild(script);
		}
	}
};
export const loadGoogleTag = (id: string) => {
	const nodeId = 'google-tag';
	const setupNodeId = nodeId + '-setup';
	if (id) {
		const script =
			document.getElementById(nodeId) || document.createElement('script');
		script.id = nodeId;
		script.setAttribute('src', 'https://www.googletagmanager.com/gtag/js?id=' + id);
		if (!script.parentNode) {
			document.body.appendChild(script);
		}
		const setupScript =
			document.getElementById(setupNodeId) || document.createElement('script');
		setupScript.id = setupNodeId;
		setupScript.innerHTML =
			'window.dataLayer = window.dataLayer || [];\n' +
			'  function gtag(){dataLayer.push(arguments);}\n' +
			'  gtag(\'js\', new Date());\n' +
			'\n' +
			'  gtag(\'config\', \'' + id + '\');';
		if (!setupScript.parentNode) {
			document.body.appendChild(setupScript);
		}
	} else {
		const script = document.getElementById(nodeId);
		if (script) {
			document.removeChild(script);
		}
		const setupScript = document.getElementById(setupNodeId);
		if (setupScript) {
			document.removeChild(setupScript);
		}
	}
};
export const isEmpty = (str: string): boolean => {
	return !str || str.trim() === '';
};

export const dateCompare = (first: any, second: any): number => {
	const firstMoment = moment(first);
	const secondMoment = moment(second);
	if (firstMoment.isBefore(secondMoment)) {
		return 1;
	}
	if (firstMoment.isAfter(secondMoment)) {
		return -1;
	}
	return 0;
};

export const numberComparator = (a: number, b: number) =>
	a > b ? 1 : a < b ? -1 : 0;
export const numberD = (x: any): number => {
	if (x === undefined) {
		return undefined;
	}
	if (x === null) {
		return null;
	}
	let candidate;
	if (x.$numberDecimal !== undefined) {
		candidate = +x.$numberDecimal;
	} else {
		candidate = +x;
	}
	if (isNaN(candidate)) {
		return null;
	}
	return candidate;
};

export const genArray = (count: number): number[] => {
	const arr: number[] = [];
	for (let i = 0; i < count; i++) {
		arr.push(i);
	}
	return arr;
};
export const maxFskOfOrder = (order: Order): number => {
	return order.orderedArticles
		.map(articleGroup => {
			return Math.max(
				articleGroup.article.fsk,
				articleGroup.groups
					.map(group => group.article.fsk)
					.reduce(
						(previousValue, currentValue) =>
							Math.max(previousValue, currentValue),
						0,
					),
			);
		})
		.reduce((prev, curr) => Math.max(prev, curr), 0);
};

export const coverFlow = {
	beforeInit() {
		const swiper = this;

		swiper.classNames.push(`${swiper.params.containerModifierClass}coverflow`);
		swiper.classNames.push(`${swiper.params.containerModifierClass}3d`);

		swiper.params.watchSlidesProgress = true;
		swiper.originalParams.watchSlidesProgress = true;
	},
	setTranslate() {
		const swiper = this;
		const {
			width: swiperWidth,
			height: swiperHeight,
			slides,
			$wrapperEl,
			slidesSizesGrid,
		} = swiper;
		const params = swiper.params.coverflowEffect;
		const isHorizontal = swiper.isHorizontal();
		const transform$$1 = swiper.translate;
		const center = isHorizontal
			? -transform$$1 + swiperWidth / 2
			: -transform$$1 + swiperHeight / 2;
		const rotate = isHorizontal ? params.rotate : -params.rotate;
		const translate = params.depth;
		// Each slide offset from center
		for (let i = 0, length = slides.length; i < length; i += 1) {
			const $slideEl = slides.eq(i);
			const slideSize = slidesSizesGrid[i];
			const slideOffset = $slideEl[0].swiperSlideOffset;
			const offsetMultiplier =
				((center - slideOffset - slideSize / 2) / slideSize) * params.modifier;

			let rotateY = isHorizontal ? rotate * offsetMultiplier : 0;
			let rotateX = isHorizontal ? 0 : rotate * offsetMultiplier;
			// var rotateZ = 0
			let translateZ = -translate * Math.abs(offsetMultiplier);

			let translateY = isHorizontal ? 0 : params.stretch * offsetMultiplier;
			let translateX = isHorizontal ? params.stretch * offsetMultiplier : 0;

			// Fix for ultra small values
			if (Math.abs(translateX) < 0.001) {
				translateX = 0;
			}
			if (Math.abs(translateY) < 0.001) {
				translateY = 0;
			}
			if (Math.abs(translateZ) < 0.001) {
				translateZ = 0;
			}
			if (Math.abs(rotateY) < 0.001) {
				rotateY = 0;
			}
			if (Math.abs(rotateX) < 0.001) {
				rotateX = 0;
			}

			const slideTransform = `translate3d(${translateX}px,${translateY}px,${translateZ}px)  rotateX(${rotateX}deg) rotateY(${rotateY}deg)`;

			$slideEl.transform(slideTransform);
			$slideEl[0].style.zIndex = -Math.abs(Math.round(offsetMultiplier)) + 1;
			if (params.slideShadows) {
				// Set shadows
				let $shadowBeforeEl = isHorizontal
					? $slideEl.find('.swiper-slide-shadow-left')
					: $slideEl.find('.swiper-slide-shadow-top');
				let $shadowAfterEl = isHorizontal
					? $slideEl.find('.swiper-slide-shadow-right')
					: $slideEl.find('.swiper-slide-shadow-bottom');
				if ($shadowBeforeEl.length === 0) {
					$shadowBeforeEl = swiper.$(
						`<div class='swiper-slide-shadow-${
							isHorizontal ? 'left' : 'top'
						}'></div>`,
					);
					$slideEl.append($shadowBeforeEl);
				}
				if ($shadowAfterEl.length === 0) {
					$shadowAfterEl = swiper.$(
						`<div class='swiper-slide-shadow-${
							isHorizontal ? 'right' : 'bottom'
						}'></div>`,
					);
					$slideEl.append($shadowAfterEl);
				}
				if ($shadowBeforeEl.length) {
					$shadowBeforeEl[0].style.opacity =
						offsetMultiplier > 0 ? offsetMultiplier : 0;
				}
				if ($shadowAfterEl.length) {
					$shadowAfterEl[0].style.opacity =
						-offsetMultiplier > 0 ? -offsetMultiplier : 0;
				}
			}
		}

		// Set correct perspective for IE10
		if (swiper.support.pointerEvents || swiper.support.prefixedPointerEvents) {
			const ws = $wrapperEl[0].style;
			ws.perspectiveOrigin = `${center}px 50%`;
		}
	},
	setTransition(duration) {
		const swiper = this;
		swiper.slides
			.transition(duration)
			.find(
				'.swiper-slide-shadow-top, .swiper-slide-shadow-right, .swiper-slide-shadow-bottom, .swiper-slide-shadow-left',
			)
			.transition(duration);
	},
};
export const firstOfObservable = async <T>(
	observable: Observable<T>,
): Promise<T> => {
	return new Promise(resolve => {
		observable.subscribe(ev => {
			resolve(ev);
		});
	});
};

export const getDisplayMode = (optionGroup: OptionGroup) => {
	if (optionGroup.displayModeMobile && optionGroup.displayModeMobile !== DisplayMode.DEFAULT) {
		return optionGroup.displayModeMobile;
	}
	return optionGroup.displayMode;
};
