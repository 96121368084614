import { Component } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ModalController } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
import validator from 'validator';

@Component({
	selector: 'app-app-restore-password-modal',
	templateUrl: './app-restore-password-modal.component.html',
	styleUrls: ['./app-restore-password-modal.component.scss'],
})
export class AppRestorePasswordModalComponent {
	email: string = '';
	isValidEmail = true;
	constructor(private modalCtrl: ModalController, private snackbarCtrl: MatSnackBar, private translate: TranslateService) {}

	static async show(modalCtrl: ModalController): Promise<any> {
		const modal = await modalCtrl.create({
			component: AppRestorePasswordModalComponent,
			cssClass: 'short-info-modal auto-height',
			componentProps: {
				recommend: false,
			},
			showBackdrop: true,
			backdropDismiss: true,
		});
		await modal.present();
		const response = await modal.onDidDismiss();
		return response.data;
	}
	sendEmail() {
		if (!validator.isEmail(this.email)) {
			this.snackbarCtrl.open(this.translate.instant('sign_up.validation.email'), null, {
				duration: 5000,
			});
			this.isValidEmail = false;

			return;
		} else {
			this.isValidEmail = true;
			this.modalCtrl.dismiss(this.email);
		}
	}
	dismiss() {
		this.modalCtrl.dismiss();
	}
	resetValidation() {
		this.isValidEmail = true;
	}
}
