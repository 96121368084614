import { environment } from './../../../environments/environment.prod';
import { Router } from '@angular/router';
import { HomePage } from './../../pages/home/home.page';
import { Component, Input, OnInit } from '@angular/core';
import { PreorderType } from '../../../smoothr-web-app-core/enums/PreorderType';
import Address from '../../../smoothr-web-app-core/models/Address';
import { MapsUtils } from '../../../smoothr-web-app-core/utils/maps-utils';
import Venue from 'src/smoothr-web-app-core/models/Venue';

@Component({
	selector: 'app-menu-toolbar',
	templateUrl: './menu-toolbar.component.html',
	styleUrls: ['./menu-toolbar.component.scss'],
})
export class MenuToolbarComponent implements OnInit {
	@Input()
	preorderType = PreorderType.TAKE_AWAY;
	addressText: string;
	environment = environment;
	constructor(private router: Router) {}

	@Input()
	set address(value: Address) {
		this.addressText = MapsUtils.addressToString(value);
	}
	@Input()
	venue: Venue;

	ngOnInit() {}
	goHome() {
		HomePage.navigate(this.router);
	}
}
